<template>
  <div class="px-4" style="padding-bottom: 140px; padding-top: 30px">
    <v-container class="pb-16 section-content-custom">
      <v-row style="margin-top: -43px">
        <v-col
          cols="9"
          lg="4"
          md="4"
          sm="6"
          xl="4"
          class="title-custom text-center"
          >ระบบหารายได้เสริม</v-col
        >
      </v-row>
      <v-row class="pt-10 justify-center">
        <v-col cols="11" sm="10" md="10" xl="9" lg="9" class="pa-0">
          <div class="pa-5 px-0">
            <div class="text-start" style="color: white">
              <h3>รับเครดิตฟรีผ่านลิ้งชวนเพื่อน</h3>
            </div>
            <div class="text-start">
              <h4 style="color: gold">
                รับเงิน 0.7% ทุกยอดแทงของเพื่อน ไม่ต้องทำเทิร์นโอเวอร์
                ให้เยอะที่สุดในวงการ
              </h4>
            </div>
          </div>
          <v-row class="justify-center my-5">
            <v-col cols="12">
              <div class="card">
                <v-row class="justify-center py-2">
                  <v-col cols="12">
                    <v-container class="px-0">
                      <v-row class="justify-center">
                        <v-col cols="12">
                          <v-row>
                            <v-col
                              cols="12"
                              lg="6"
                              md="6"
                              xl="6"
                              sm="6"
                              class="
                                d-flex
                                flex-column
                                align-center
                                align-sm-start
                                align-md-start
                                align-lg-start
                                align-xl-start
                              "
                              ><div class="text-start">
                                <h3 style="color: white">
                                  ลิงค์สำหรับชวนเพื่อน
                                </h3>
                              </div></v-col
                            >
                            <v-col
                              cols="12"
                              lg="6"
                              md="6"
                              xl="6"
                              sm="6"
                              class="
                                d-flex
                                flex-column
                                align-center
                                align-sm-end
                                align-md-end
                                align-lg-end
                                align-xl-end
                              "
                            >
                              <div style="color: white">
                                โบนัสคงเหลือ:
                                {{ userInvite.availableAmount }} ยอดขั้นต่ำ:
                                {{ userInvite.minimumAmount }}
                              </div>
                            </v-col>
                          </v-row>
                          <div class="text-start mt-3">
                            <h4 style="color: #ef6123">รหัสชวนเพื่อน:</h4>
                          </div>
                          <div
                            class="text-start"
                            style="font-size: 24px; color: white"
                          >
                            {{ userInvite.inviteKey }}
                          </div>
                          <div class="text-start mt-3">
                            <h4 style="color: #ef6123">ลิงค์ชวนเพื่อน:</h4>
                          </div>
                          <div
                            class="text-start"
                            style="font-size: 18px; color: white"
                          >
                            <v-text-field
                              dark
                              class="pa-0"
                              color="#961292"
                              hide-details
                              readonly
                              id="reflink"
                              v-model="urlInvite"
                            ></v-text-field>
                          </div> </v-col
                        ><v-col
                          cols="6"
                          md="4"
                          sm="4"
                          lg="4"
                          xl="4"
                          class="
                            mt-3
                            border-card-bank1
                            d-flex
                            flex-column
                            justify-center
                          "
                        >
                          <div class="pb-2 text-center" style="color: white">
                            หรือแสกน QR
                          </div>
                          <vue-qrcode :value="urlInvite" />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container class="px-0">
                      <v-row class="justify-center pt-3">
                        <v-col
                          class="pr-1"
                          cols="6"
                          md="5"
                          sm="5"
                          lg="5"
                          xl="5"
                        >
                          <v-btn
                            height="48"
                            @click="doCopy()"
                            block
                            depressed
                            class="btn-purple"
                            style="color: white"
                          >
                            <v-icon class="pr-1">mdi-content-copy</v-icon>
                            คัดลอกลิงค์
                          </v-btn></v-col
                        >
                        <v-col
                          class="pl-1"
                          cols="6"
                          md="5"
                          sm="5"
                          lg="5"
                          xl="5"
                        >
                          <v-btn
                            height="48"
                            block
                            depressed
                            color="white"
                            class="btn-orange"
                            @click="onGetBonusInvite()"
                          >
                            <div style="color: white">รับคอมมิชชั่น</div>
                          </v-btn></v-col
                        >
                      </v-row>
                    </v-container>
                    <v-container class="px-0">
                      <v-divider
                        class="mt-1 mb-3"
                        style="background-color: #ffc107"
                      ></v-divider>
                      <div>
                        <div class="d-flex pt-3">
                          <v-icon style="color: gold" class="pr-1"
                            >mdi-format-list-bulleted-square</v-icon
                          >
                          <h4 style="color: gold">รายละเอียด</h4>
                        </div>
                        <ul
                          class="pt-1"
                          v-for="(item, index) in detailData"
                          :key="index"
                        >
                          <li
                            class="text-start"
                            style="font-size: 15px; color: white"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                      </div>
                    </v-container>
                    <v-container class="px-0">
                      <div>
                        <div class="d-flex pt-3">
                          <v-icon style="color: gold" class="pr-1"
                            >mdi-format-list-bulleted-square</v-icon
                          >
                          <h4 style="color: gold">ตัวอย่าง</h4>
                        </div>
                        <ul
                          class="pt-1"
                          v-for="(item, index) in ExData"
                          :key="index"
                        >
                          <li
                            class="text-start"
                            style="font-size: 15px; color: white"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                      </div>
                    </v-container>
                    <v-container class="px-0">
                      <div class="text-start">
                        <v-col cols="12" class="border-card mb-4">
                          <h4 style="color: gold">
                            <v-icon style="color: gold">
                              mdi-account-multiple</v-icon
                            >
                            เพื่อนของคุณ
                          </h4>

                          <div class="black mt-4">
                            <v-data-table
                              dark
                              :items="userInvite.downlines"
                              :loading="isLoading"
                              :headers="headersInviteFriend"
                              class="elevation-1"
                              mobile-breakpoint="0"
                            >
                              <template #item="{ item }">
                                <tr class="text-center">
                                  <td>
                                    {{ item.name }}
                                  </td>
                                  <td>
                                    {{ item.userCount }}
                                  </td>
                                  <td>
                                    {{ item.totalFund.toLocaleString() }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div class="text-center mt-5" style="color: black">
                            ขั้นต่ำ 500 บาท ไม่ต้องทำเทิร์น
                          </div>
                          <div style="color: gold" class="text-center">
                            ยอดเทิร์นของเพื่อนที่ติดโปรโมชั่นจะไม่ถูกนำมาคำนวณ
                          </div>
                        </v-col>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueQrcode from "vue-qrcode";
import config from "../../config/config";
export default {
  computed: {
    urlInvite() {
      let url = `${config.prod.api_invite}/register/${this.userInvite.inviteKey}`;
      return url;
    },
    ...mapState({
      userInvite: (state) => state.invite.userInvite,
    }),
  },
  methods: {
    doCopy() {
      let CodeToCopy = document.querySelector("#reflink");
      CodeToCopy.focus();
      CodeToCopy.select();
      try {
        document.execCommand("copy");
        this.opentoast("success", "คัดลอกลิงค์แล้ว");
        this.copyClip = true;
      } catch (err) {
        this.opentoast("error", "ไม่สามารถคัดลอกลิงค์ได้");
      }
    },
    opentoast(type, massage) {
      this.$toast[type](massage, {
        position: "top-right",
      });
    },
    async getUserInvite() {
      await this.$store.dispatch("invite/userGetInvite");
    },
    async onGetBonusInvite() {
      this.isLoadingBtn = true;

      let res = await this.$store.dispatch("invite/getBonusInvite");

      if (res.msg) {
        this.opentoast("success", res.data.data.message);
      } else {
        this.opentoast("error", res.data.data.message);
      }
    },
  },
  components: {
    VueQrcode,
  },
  async mounted() {
    this.getUserInvite();
  },
  data: () => ({
    isLoaingQR: false,
    timerID: null,
    isLoading: false,
      headersInviteFriend: [
      {
        text: "",
        align: "center",
        value: "name",
        sortable: false,
      },
      {
        text: "จำนวนเพื่อน",
        align: "center",
        value: "userCount",
        sortable: false,
      },
      {
        text: "ยอดเล่นทั้งหมด	",
        align: "center",
        value: "totalFund",
        sortable: false,
      },
    ],
    linkinvite: "https://vipgame66.com/signup/join?refID=OFCNPQ",
    linkForInvite: "https://nh1168.xyz/register/ECBHH",
    detailData: [
      {
        text: "หารายได้เสริมให้สมัครสมาชิกผ่านลิ้งค์รับทรัพย์​ (ด้านล่าง)",
      },
      {
        text: "รับฟรีโบนัสจากยอดเทิร์นของเพื่อน 0.6-0.9% แล้วแต่เกม",
      },
      {
        text: "ชวนครั้งเดียวก็คุ้ม รับโบนัสได้ตลอดชีพ",
      },
      {
        text: "หากพบว่ามีการปั๊มเทิร์น จะถูกระงับทันที",
      },
    ],
    ExData: [
      {
        text: "นาย A แนะนำนาย B มาสมัครสมาชิก",
      },
      {
        text: "นาย A แนะนำนาย B มาสมัครสมาชิก",
      },
      {
        text: "นาย A แนะนำนาย B มาสมัครสมาชิก",
      },
      {
        text: "นาย B แนะนำนาย C มาสมัครสมาชิก",
      },
      {
        text: "นาย C มียอดเทิร์นเล่น 100,000 บาท",
      },
      {
        text: "นาย B ได้รับโบนัสทันที 600 บาท (0.6% จาก 100,000)",
      },
      {
        text: "และนาย A ยังได้รับโบนัสอีกด้วย 60 บาท (0.06% จาก 100,000)",
      },
      {
        text: "รับโบนัสได้สูงสุดถึง 10 ขั้นสมาชิก (0.6 0.06 0.006 0.006% 0.0006% ...)",
      },
    ],
  }),
};
</script>
<style scoped>
.border-card-bank1 {
  opacity: 0.98;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(196, 40, 150, 0.5) 0%,
    rgba(24, 0, 15, 0.5) 100%
  );
}
.text-title {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-login {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}

.btn-redeem {
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #1b1b1b 0% 65%,
    #141414 95% 100%
  );
  border-width: 3px;
  color: rgb(255, 255, 255);
}
.btn-copy {
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #f0c02c 0% 65%,
    #f0c02c 95% 100%
  );
  border-width: 3px;
  color: rgb(0, 0, 0);
}

.title-custom {
  top: -2% !important;
}
</style>